export const COLORS = {
  saladGreen: "#2ECC71",
  hoverGreen: "#2fb568",
  creme: "#DDAF94",
  darkCreme: "#DDAF94",
  brown: "#BC947C",
  white: "#FFF",
  gray: "#616161",
  black: "#000000",
  blue: "#0070E0",
  hoverBlue: "#034a91",
  red: "#E50914",
  redHover: "#ba0710",
  bgGray: "#F2F2F2",
};
